// 融资方案
<template>
  <div>
        <base-form
          :componentList="financingSchemeFrom"
          :showBtns="false"
          :formAttrs="{
            model: financingData,
            labelWidth: '150px',
          }"
          class="formStyle"
          ref="formDataList"
        />
  </div>
</template>
<script>
import { financingSchemeFrom } from '../utils/financing-info-config'
import BaseForm from '@/components/common/base-form/base-form'
// import { contactApi } from '@/api/contactApi'

export default {
  components: { BaseForm },
  props: {
    newfinancingData: Object
  },
  data () {
    return {
      finRepayStyleData: [], // 还款方式数据
      financingData: {}
    }
  },
  computed: {
    financingSchemeFrom () {
      return financingSchemeFrom(this)
    }
  },

  watch: {
    newfinancingData: {
      handler (val) {
        if (val) {
          this.financingData = val
        }
      },
      deep: true
    }
  },
  methods: {
    submit (data) {
      this.$set(this.financingData, 'finRepayPlans', data)
    },

    // 修改融资期限单位
    changeValidTimeUnit (val) {
      this.$set(this.financingData, 'financeTermUnit', val)
    }
    // // 获取合同列表
    // getContactlList () {
    //   contactApi.getContactlList().then(res => {
    //     console.log(res.data)
    //   })
    // }
  }

}
</script>
<style lang="scss" scoped>
  /deep/ .el-form-item__content {
    height: auto !important;
  }
</style>
